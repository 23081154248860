import type {ConnectorId} from '@cohort/shared/apps';
import type {ActionStruct, PerkIntegrationStruct} from '@cohort/shared/apps/app';
import {parseJwtToken} from '@cohort/wallet/lib/Parsers';
import type {
  ExecuteActionDataWDto,
  ExecuteActionResponseWDto,
  ExecuteAsyncActionDataWDto,
} from '@cohort/wallet-schemas/apps';
import {ExecuteActionResponseWSchema} from '@cohort/wallet-schemas/apps';
import type {CampaignStoreWDto} from '@cohort/wallet-schemas/campaign';
import type {StandaloneChallengeWDto} from '@cohort/wallet-schemas/campaign';
import {CampaignStoreWSchema} from '@cohort/wallet-schemas/campaign';
import {StandaloneChallengeWSchema} from '@cohort/wallet-schemas/campaign';
import type {
  ChallengeParticipationWDto,
  VerifyChallengeStepWDto,
} from '@cohort/wallet-schemas/challengeParticipation';
import {ChallengeParticipationWSchema} from '@cohort/wallet-schemas/challengeParticipation';
import type {
  GetAuthorizationUrlDataWDto,
  GetAuthorizationUrlResponseWDto,
  GetConnectionResponseWDto,
} from '@cohort/wallet-schemas/connections';
import {GetConnectionResponseWSchema} from '@cohort/wallet-schemas/connections';
import {GetAuthorizationUrlResponseWSchema} from '@cohort/wallet-schemas/connections';
import type {MerchantInitWDto} from '@cohort/wallet-schemas/merchant';
import {MerchantInitWSchema} from '@cohort/wallet-schemas/merchant';
import type {
  FindOrderByPaymentSessionIdResponseWDto,
  OrderWDto,
  PaymentSessionWDto,
} from '@cohort/wallet-schemas/order';
import {
  FindOrderByPaymentSessionIdResponseWSchema,
  OrderWSchema,
  PaymentSessionWSchema,
} from '@cohort/wallet-schemas/order';
import type {
  ConfirmTransferWDto,
  DigitalAssetTransferInvitationWDto,
  DigitalAssetTransferJobWDto,
  OwnershipWDto,
  RequestOwnershipTransferDataWDto,
} from '@cohort/wallet-schemas/ownership';
import {
  ConfirmTransferWSchema,
  DigitalAssetTransferInvitationWSchema,
  DigitalAssetTransferJobWSchema,
  OwnershipWSchema,
} from '@cohort/wallet-schemas/ownership';
import type {PerkAccessWDto, PerkPrivateContentUrlWDto} from '@cohort/wallet-schemas/perkAccess';
import {PerkAccessWSchema, PerkPrivateContentUrlWSchema} from '@cohort/wallet-schemas/perkAccess';
import type {PerkUsageInputWDto, PerkUsageWDto} from '@cohort/wallet-schemas/perkUsage';
import {PerkUsageWSchema} from '@cohort/wallet-schemas/perkUsage';
import type {SpaceWDto, SyncSpaceResultWDto} from '@cohort/wallet-schemas/space';
import {SpaceWSchema, SyncSpaceResultWSchema} from '@cohort/wallet-schemas/space';
import type {PatchUserWDto, UserWDto} from '@cohort/wallet-schemas/user';
import {UserWSchema} from '@cohort/wallet-schemas/user';
import type {UserAttributeWDto} from '@cohort/wallet-schemas/userAttributes';
import {ListUserAttributesResponseWSchema} from '@cohort/wallet-schemas/userAttributes';
export interface Endpoint<T> {
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  url: string;
  parser?: (data: unknown) => T;
  body?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  headers?: Record<string, string>;
}

export const AppsApi = {
  executeAction: <T extends ActionStruct = ActionStruct>(
    data: ExecuteActionDataWDto<T>
  ): Endpoint<ExecuteActionResponseWDto<T>> => ({
    method: 'POST',
    url: '/v1/apps/execute-action',
    data,
    parser: ExecuteActionResponseWSchema.parse as (data: unknown) => ExecuteActionResponseWDto<T>,
  }),
  executeAsyncAction: <T extends ActionStruct = ActionStruct>(
    data: ExecuteAsyncActionDataWDto<T>
  ): Endpoint<void> => ({
    method: 'POST',
    url: '/v1/apps/execute-async-action',
    data,
    parser: () => {},
  }),
};

export const ConnectionsApi = {
  getConnectionForConnector: (connectorId: ConnectorId): Endpoint<GetConnectionResponseWDto> => ({
    method: 'GET',
    url: `/v1/connections/connector/${connectorId}`,
    parser: GetConnectionResponseWSchema.parse,
  }),
  getOauthAuthorizationUrl: (
    data: GetAuthorizationUrlDataWDto
  ): Endpoint<GetAuthorizationUrlResponseWDto> => ({
    method: 'POST',
    url: '/v1/connections/oauth-authorization-url',
    data: {
      connectorId: data.connectorId,
      embedUrl: data.embedUrl,
      xpsRedirectUrl: data.xpsRedirectUrl,
      existingConnectionId: data.existingConnectionId,
    },
    parser: GetAuthorizationUrlResponseWSchema.parse,
  }),
  deleteConnection: (connectionId: string): Endpoint<void> => ({
    method: 'DELETE',
    url: `/v1/connections/${connectionId}`,
    data: {},
  }),
};

export const UserApi = {
  logIn: (): Endpoint<UserWDto> => ({
    method: 'POST',
    url: '/v1/user/login',
    parser: UserWSchema.parse,
  }),
  deleteUser: (): Endpoint<void> => ({
    method: 'DELETE',
    url: '/v1/user',
    data: {},
  }),
  patchUser: (data: PatchUserWDto): Endpoint<UserWDto> => ({
    method: 'PATCH',
    url: `/v1/user`,
    parser: UserWSchema.parse,
    data,
  }),
  sendSigninCode: (
    email: string,
    merchantSlug: string,
    turnstileToken: string
  ): Endpoint<void> => ({
    method: 'POST',
    url: '/v1/user/send-signin-code-email',
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    data: {
      email,
      merchantSlug,
    },
  }),
  verifyOtpCode: (
    merchantSlug: string,
    email: string,
    code: string,
    turnstileToken: string
  ): Endpoint<string> => ({
    method: 'POST',
    url: '/v1/user/verify-otp',
    headers: {
      'x-turnstile-token': turnstileToken,
    },
    parser: parseJwtToken,
    data: {
      merchantSlug,
      email,
      code,
    },
  }),
  verifyAuthToken: (authToken: string): Endpoint<string> => ({
    method: 'POST',
    url: '/v1/user/verify-auth-token',
    parser: parseJwtToken,
    data: {authToken},
  }),
};

export const CampaignsApi = {
  getStoreBySlug: (
    merchantId: string,
    campaignSlug: string,
    redeemCode?: string
  ): Endpoint<CampaignStoreWDto> => ({
    method: 'GET',
    url: `/v1/merchants/${merchantId}/stores/${campaignSlug}`,
    parser: CampaignStoreWSchema.parse,
    params: {redeemCode},
  }),
  getChallengeBySlug: (
    merchantId: string,
    challengeSlug: string
  ): Endpoint<StandaloneChallengeWDto> => ({
    method: 'GET',
    url: `/v1/merchants/${merchantId}/challenges/${challengeSlug}`,
    parser: StandaloneChallengeWSchema.parse,
  }),
};

export const OrdersApi = {
  createPaymentSession: (
    campaignSlug: string,
    currency: string,
    redeemCode: string | undefined
  ): Endpoint<PaymentSessionWDto> => ({
    method: 'POST',
    url: `/v1/campaigns/${campaignSlug}/create-payment-session`,
    data: {redeemCode, currency},
    parser: PaymentSessionWSchema.parse,
  }),
  createFreeOrder: (campaignId: string, redeemCode: string | undefined): Endpoint<OrderWDto> => ({
    method: 'POST',
    url: `/v1/orders`,
    parser: OrderWSchema.parse,
    data: {campaignId, redeemCode},
  }),
  getOrder: (orderId: string): Endpoint<OrderWDto> => ({
    method: 'GET',
    url: `/v1/orders/${orderId}`,
    parser: OrderWSchema.parse,
  }),
  findOrderByPaymentSessionId: (
    paymentSessionId: string
  ): Endpoint<FindOrderByPaymentSessionIdResponseWDto> => ({
    method: 'GET',
    url: `/v1/orders`,
    parser: FindOrderByPaymentSessionIdResponseWSchema.parse,
    params: {paymentSessionId},
  }),
};

export const SpacesApi = {
  getMerchantSpace: (): Endpoint<SpaceWDto> => ({
    method: 'GET',
    url: `/v1/space`,
    parser: SpaceWSchema.parse,
  }),
  syncSpaceOwnerships: (): Endpoint<SyncSpaceResultWDto> => ({
    method: 'POST',
    url: `/v1/space/sync`,
    parser: SyncSpaceResultWSchema.parse,
  }),
};

export const NftTransferInvitationApi = {
  getNftTransferInvitation: (
    invitationId: string
  ): Endpoint<DigitalAssetTransferInvitationWDto> => ({
    method: 'GET',
    url: `/v1/invitations/${invitationId}`,
    parser: DigitalAssetTransferInvitationWSchema.parse,
  }),
  acceptDigitalAssetTransferInvitation: (
    invitationId: string
  ): Endpoint<DigitalAssetTransferJobWDto> => ({
    method: 'POST',
    url: `/v1/invitations/${invitationId}/accept`,
    parser: DigitalAssetTransferJobWSchema.parse,
  }),
};

export const PerkAccessesApi = {
  getPerkAccess: (perkAccessId: string): Endpoint<PerkAccessWDto> => ({
    method: 'GET',
    url: `/v1/perk-accesses/${perkAccessId}`,
    parser: PerkAccessWSchema.parse,
  }),
  generatePrivateContentUrl: (perkAccessId: string) =>
    ({
      method: 'POST',
      url: `/v1/perk-accesses/${perkAccessId}/generate-private-content-url`,
      parser: PerkPrivateContentUrlWSchema.parse,
    }) satisfies Endpoint<PerkPrivateContentUrlWDto>,
  activatePerkAccess: (perkAccessId: string): Endpoint<PerkAccessWDto> => ({
    method: 'POST',
    url: `/v1/perk-accesses/${perkAccessId}/activate`,
    parser: response => PerkAccessWSchema.parse(response),
  }),
  doUsePerkAccess: <T extends PerkIntegrationStruct = PerkIntegrationStruct>(
    perkAccessId: string,
    userConnectionId: string | null,
    usageData: T['UsageInputData']
  ): Endpoint<PerkUsageWDto> => ({
    method: 'POST',
    url: `/v1/perk-accesses/${perkAccessId}/use`,
    parser: response => PerkUsageWSchema.parse(response),
    data: {data: usageData, userConnectionId} satisfies PerkUsageInputWDto,
  }),
};

export const OwnershipsApi = {
  getOwnership: (ownershipId: string): Endpoint<OwnershipWDto> => ({
    method: 'GET',
    url: `/v1/ownerships/${ownershipId}`,
    parser: OwnershipWSchema.parse,
  }),
  getNftTransferJob: (transferId: string): Endpoint<DigitalAssetTransferJobWDto> => ({
    method: 'GET',
    url: `/v1/transfers/${transferId}`,
    parser: DigitalAssetTransferJobWSchema.parse,
  }),
  cancelNftTransferInvitation: (
    ownershipId: string,
    invitationId: string
  ): Endpoint<OwnershipWDto> => ({
    method: 'POST',
    url: `/v1/ownerships/${ownershipId}/invitations/${invitationId}/cancel`,
    parser: OwnershipWSchema.parse,
  }),
  confirmTransfer: (
    ownershipId: string | undefined,
    validationCode: string | null
  ): Endpoint<ConfirmTransferWDto> => ({
    method: 'POST',
    url: `/v1/ownerships/${ownershipId}/confirm-transfer`,
    parser: ConfirmTransferWSchema.parse,
    data: {
      validationCode,
    },
  }),
  requestOwnershipTransfer: (
    ownershipId: string,
    data: RequestOwnershipTransferDataWDto
  ): Endpoint<object> => ({
    method: 'POST',
    url: `/v1/ownerships/${ownershipId}/request-transfer`,
    parser: () => {
      return {};
    },
    data,
  }),
};

export const MerchantsApi = {
  getMerchantBySlug: (slug: string): Endpoint<MerchantInitWDto> => ({
    method: 'GET',
    url: `/v1/merchants/slug/${slug}`,
    parser: MerchantInitWSchema.parse,
  }),
};

export const ChallengeParticipationsApi = {
  joinChallengeCampaign: (campaignId: string): Endpoint<ChallengeParticipationWDto> => ({
    method: 'POST',
    url: `/v1/challenge-participations`,
    parser: ChallengeParticipationWSchema.parse,
    data: {
      campaignId,
    },
  }),
  completeChallenge: (challengeParticipationId: string): Endpoint<ChallengeParticipationWDto> => ({
    method: 'POST',
    url: `/v1/challenge-participations/${challengeParticipationId}/complete`,
    parser: ChallengeParticipationWSchema.parse,
  }),
  verifyStep: (
    challengeParticipationId: string,
    body: VerifyChallengeStepWDto
  ): Endpoint<ChallengeParticipationWDto> => ({
    method: 'POST',
    url: `/v1/challenge-participations/${challengeParticipationId}/verify-step`,
    parser: ChallengeParticipationWSchema.parse,
    data: body,
  }),
};

export const UserAttributesApi = {
  listUserAttributes: (userPropertyIds: Array<string>): Endpoint<Array<UserAttributeWDto>> => ({
    method: 'GET',
    url: `/v1/user-attributes`,
    parser: ListUserAttributesResponseWSchema.parse,
    params: {userPropertyIds},
  }),
};

import type {DiscordAppStruct} from '@cohort/shared/apps/discord';
import {DiscordAppSpec} from '@cohort/shared/apps/discord';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {DiscordPrivateAccessPerkIntegration} from '@cohort/wallet/apps/discord/perkIntegrations/privateAccess/PerkIntegration';
import {DiscordHasRoleTriggerIntegration} from '@cohort/wallet/apps/discord/triggerIntegrations/hasRole/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const DiscordApp: App<DiscordAppStruct> = {
  spec: DiscordAppSpec,
  perkIntegrations: [DiscordPrivateAccessPerkIntegration],
  triggerIntegrations: [DiscordHasRoleTriggerIntegration as TriggerIntegration],
  logo: <SvgAppIcon name="discord" height={40} width={40} />,
};

export default DiscordApp;

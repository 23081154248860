import {Footer} from '@cohort/wallet/components/Footer';
import Navbar from '@cohort/wallet/components/navigation/NavBar';
import {campaignsKeys} from '@cohort/wallet/hooks/api/Campaign';
import {useAppStore} from '@cohort/wallet/hooks/stores/app';
import {useUserStore} from '@cohort/wallet/hooks/stores/user';
import useChallengeContext from '@cohort/wallet/hooks/useChallengeContext';
import {useMerchantContext} from '@cohort/wallet/hooks/useMerchantContext';
import {useNavigateWithTransition} from '@cohort/wallet/hooks/useNavigateWithTransition';
import BaseLayout from '@cohort/wallet/layouts/BaseLayout';
import ChallengeDropdownNavigation from '@cohort/wallet/layouts/challenges/ChallengeDropdownNavigation';
import {setAmplitudeMetadata} from '@cohort/wallet/lib/Tracking';
import {useQueryClient} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';

const ChallengeLayout = (): JSX.Element => {
  const {t} = useTranslation('layouts', {
    keyPrefix: 'challenges.challengeLayout',
  });
  const {challengeSlug} = useParams();
  const merchant = useMerchantContext();
  const {campaign} = useChallengeContext();
  const navigateWithTransition = useNavigateWithTransition();
  const queryClient = useQueryClient();
  const isLoggedIn = useUserStore(store => store.isLoggedIn);
  const [wasLoggedIn, setWasLoggedIn] = useState(isLoggedIn);
  const withNavbar = useAppStore(store => store.withNavbar);

  useEffect(() => {
    setAmplitudeMetadata({merchant, challenge: campaign, rootPath: 'challenges'});
  }, [campaign, merchant]);

  // refresh campaign at login
  useEffect(() => {
    if (wasLoggedIn === false && isLoggedIn) {
      setWasLoggedIn(true);
      queryClient.invalidateQueries(
        campaignsKeys.getChallengeBySlug(merchant.id, campaign.challenge.slug)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const navbar = withNavbar ? (
    <Navbar
      logoFileKey={merchant.logoFileKey}
      appTitle={t('challengeTitle', {name: merchant.name})}
      challengeSlug={challengeSlug}
      handleBackButtonClick={() => navigateWithTransition(-1, undefined, 'backward')}
    >
      <div className="flex h-full w-full items-center justify-end">
        <ChallengeDropdownNavigation />
      </div>
    </Navbar>
  ) : (
    <div className="flex w-full items-center justify-end pr-4 pt-2 md:pr-8">
      <ChallengeDropdownNavigation />
    </div>
  );

  return (
    <BaseLayout
      navbar={navbar}
      footer={
        <div className="flex w-full justify-center">
          <div className="w-full md:max-w-[--challenge-page-width] md:px-8">
            <Footer />
          </div>
        </div>
      }
    />
  );
};

export default ChallengeLayout;

import type {GarminConnectAppStruct} from '@cohort/shared/apps/garmin-connect';
import {GarminConnectAppSpec} from '@cohort/shared/apps/garmin-connect';
import {SvgAppIcon} from '@cohort/shared-frontend/common/AppIcons';
import type {App} from '@cohort/wallet/apps';
import {GarminConnectBeActiveTriggerIntegration} from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/beActive/TriggerIntegration';
import {GarminConnectCompleteActivityTriggerIntegration} from '@cohort/wallet/apps/garmin-connect/triggerIntegrations/completeActivity/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const GarminConnectApp: App<GarminConnectAppStruct> = {
  spec: GarminConnectAppSpec,
  perkIntegrations: [],
  triggerIntegrations: [
    GarminConnectBeActiveTriggerIntegration,
    GarminConnectCompleteActivityTriggerIntegration,
  ] as TriggerIntegration[],
  logo: <SvgAppIcon name="garmin-connect" height={40} width={40} />,
};

export default GarminConnectApp;

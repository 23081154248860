import type {CohortFillFormTriggerStruct} from '@cohort/shared/apps/cohort/triggers/fillForm';
import {CohortFillFormTriggerIntegrationSpec} from '@cohort/shared/apps/cohort/triggers/fillForm';
import CohortFillFormUsageComponent from '@cohort/wallet/apps/cohort/triggerIntegrations/fillForm/UsageComponent';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

export const CohortFillFormTriggerIntegration: TriggerIntegration<CohortFillFormTriggerStruct> = {
  type: 'embedded',
  spec: CohortFillFormTriggerIntegrationSpec,
  usageComponent: CohortFillFormUsageComponent,
  disableExponentialBackoff: true,
};

import type {CohortAppStruct} from '@cohort/shared/apps/cohort';
import {CohortAppSpec} from '@cohort/shared/apps/cohort';
import type {App} from '@cohort/wallet/apps';
import {CohortFormPerkIntegration} from '@cohort/wallet/apps/cohort/perkIntegrations/form/PerkIntegration';
import {CohortQrCodePerkIntegration} from '@cohort/wallet/apps/cohort/perkIntegrations/qrCode/PerkIntegration';
import {CohortBeInCohortTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/beInCohort/TriggerIntegration';
import {CohortDigitalAssetOwnedTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/digitalAssetOwned/TriggerIntegration';
import {CohortFillFormTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/fillForm/TriggerIntegration';
import {CohortPerkUsedTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/perkUsed/TriggerIntegration';
import {CohortScanQrCodeTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/scanQrCode/TriggerIntegration';
import {CohortUserPropertyConditionMetTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/userPropertyConditionMet/TriggerIntegration';
import {CohortVisitLinkTriggerIntegration} from '@cohort/wallet/apps/cohort/triggerIntegrations/visitLink/TriggerIntegration';
import type {TriggerIntegration} from '@cohort/wallet/apps/TriggerIntegration';

const CohortApp: App<CohortAppStruct> = {
  spec: CohortAppSpec,
  perkIntegrations: [CohortFormPerkIntegration, CohortQrCodePerkIntegration],
  triggerIntegrations: [
    CohortBeInCohortTriggerIntegration,
    CohortDigitalAssetOwnedTriggerIntegration,
    CohortFillFormTriggerIntegration,
    CohortPerkUsedTriggerIntegration,
    CohortScanQrCodeTriggerIntegration,
    CohortVisitLinkTriggerIntegration,
    CohortUserPropertyConditionMetTriggerIntegration,
  ] as TriggerIntegration[],
  logo: null,
};

export default CohortApp;
